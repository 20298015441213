.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

.top-tags {
    bottom: auto;
    top: 35px;
}

.tags {
    color: #791025;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;
    font-family: 'La Belle Aurore';
}

.botton-tag-html {
    margin-left: -20px;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

.about-page,
.contact-page,
.portfolio-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;
    

        h1 {
            font-size: 53px;
            font-family: 'Coolvetica';
            color: #fff;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 40px;
            left: 10px;
        }

        &::before {
            content: '<h1>';
            color: black;
            font-family: 'La Belle Aurore';
            font-size: 18px;
            position: absolute;
            margin-top: -10px;
            left: -10px;
            opacity: 0.7;
            line-height: 18px;
        }

        &::after {
            content: '</h1>';
            color: black;
            font-family: 'La Belle Aurore';
            font-size: 18px;
            line-height: 18px;
            position: absolute;
            left: -30px;
            bottom: -20px;
            margin-left: 20px;
            opacity: 0.7;
        }

        p {
            font-size: 18px;
            font-family: 'sans-serif';
            color: #fff;
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s;

            &:nth-of-type(1) {
                animation-delay: 1.1s;
            }

            &:nth-of-type(2) {
                animation-delay: 1.2s;
            }

            &:nth-of-type(3) {
                animation-delay: 1.3s;
            }
        }
    }

    .text-animate-hover {
        &:hover {
            color: black;
        }
    }
}

@media screen and (max-width: 1200px) {
    .page {
        position: initial;
    } 

    .container {
        position: initial;
        height: auto;
        min-height: auto;

        &.about-page,
        &.contact-page,
        &.portfolio-page {
            
            .text-zone {
                position: initial;
                transform: none;
                width: 100%;
                display: block;
                padding: 20px;
                box-sizing: border-box;
                
            }
        }
    }
}

